<script lang="ts">
	import type { Image } from "../../../../core/schema/Image";
	import { parseSourceSet } from "./parseSourceSet.js";
	import type { SourceSetQuery } from "./SourceSetQuery.js";
	import { thumb } from "../../../../core/utils/thumb.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import DynamicPictureInner from "./DynamicPictureInner.svelte";
	import { sortSourceSetQuery } from "./sortSourceSetQuery.js";

	let className = "";
	export { className as class };

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy" = "lazy";
	export let image: Image;
	export let alt: string;
	export let sourcesets: Partial<Record<SourceSetQuery, [number, number]>> = {};
	export let preload: boolean | undefined = undefined;
	export let lightboxId: string | undefined = undefined;
	export let lightboxWidth = width;
	export let bucket: "storage" | "upload";
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, [number, number]][];

	const { STORAGE_URL, UPLOAD_URL } = getGlobalSettings();

	$: bucketUrl = {
		storage: STORAGE_URL,
		upload: UPLOAD_URL,
	}[bucket];
</script>

<svelte:head>
	{#if preload}
		{#each sortedSourcesets as [query, [width, height]]}
			<link
				rel="preload"
				as="image"
				imagesrcset={`${thumb(bucketUrl, width, height, image)} 1x`}
				media={parseSourceSet(query)}
				fetchpriority="high"
			/>
		{/each}
	{/if}
</svelte:head>

{#if lightboxId}
	<a
		class="block {className}"
		href={thumb(bucketUrl, lightboxWidth, 0, image)}
		data-fslightbox={lightboxId}
		data-type="image"
		data-caption={alt}
		on:mouseover
		on:focus
		on:mouseout
		on:blur
	>
		<DynamicPictureInner
			{bucketUrl}
			{width}
			{height}
			{imgClass}
			{loading}
			{image}
			{alt}
			{sourcesets}
			bind:naturalHeight
			bind:naturalWidth
		/>
	</a>
{:else}
	<div class={className}>
		<DynamicPictureInner
			{bucketUrl}
			{width}
			{height}
			{imgClass}
			{loading}
			{image}
			{alt}
			{sourcesets}
			bind:naturalHeight
			bind:naturalWidth
		/>
	</div>
{/if}
